import React from 'react';
import {
  Feature,
  NotificationPreferencesProperties,
  NotificationsPreferencesProperties,
  Product,
  ReportCharacteristic,
  ReportStatus,
  UserProductsProperties,
  UserType,
} from 'interfaces/api';
import { filter, get } from 'lodash';
import { FormItemRenderProps } from 'components/Form/Item';
import messages from 'messages';
import { FormLayout, Guard, useGuard } from 'containers';
import { ItemType } from 'rc-collapse/es/interface';
import { Alert, Collapse, Container, Icon, IconProps } from 'components';
import { faBellOn, faBellSlash } from '@fortawesome/pro-regular-svg-icons';
import { Translate, useIntlStoreSelectors } from 'providers';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { Color, Message } from 'interfaces';
import { faBolt, faFileMedicalAlt, faFilePlus } from '@fortawesome/pro-light-svg-icons';
import { toggleArrayItem } from 'utils/helpers';
import { useEnv } from 'providers/EnvProvider';

type NotificationSettingProperties = {
  [K in keyof NotificationsPreferencesProperties]: NotificationsPreferencesProperties[K] extends NotificationPreferencesProperties ? K : never
}[keyof NotificationsPreferencesProperties];

type NotificationPanelProps = {
  icon: IconProps;
  title: Message;
  preferencesKey: NotificationSettingProperties;
  children?: React.ReactNode;
  hasWarning?: boolean;
};

export const NotificationSettingsForm: React.ComponentType<FormItemRenderProps<NotificationsPreferencesProperties> & {
  embedded?: boolean;
  userFeatures?: Record<Feature, boolean>;
  userProducts?: UserProductsProperties;
}> = (props) => {

  const { Switch, value, onChange, userFeatures, userProducts } = props;

  const translate = useIntlStoreSelectors.translate();
  const guard = useGuard();
  const APP_NAME = useEnv.APP_NAME();

  const getProductEnabled = (product: Product) => userProducts ? userProducts[product] : guard({ product }, () => true);
  const getFeatureEnabled = (feature: Feature) => userFeatures ? userFeatures[feature] : guard({ feature }, () => true);

  const createItem = ({ icon, title, preferencesKey, hasWarning, children }: NotificationPanelProps): ItemType => {

    const enabled = value[preferencesKey].enabled;
    const enabledIcon = enabled ? <Icon icon={faBellOn}/> : <Icon icon={faBellSlash}/>;

    return {
      key: preferencesKey,
      extra: enabledIcon,
      label: (
        <Container horizontal>
          <Container center padding className={'notification-settings-icon'}>
            <Icon {...icon}/>
          </Container>
          <Container className={'notification-settings-label'}>
            <h2>
              <Translate message={title}/>
            </h2>
            <span>
              {hasWarning ? <Icon icon={faTriangleExclamation} color={Color.Yellow} style={{ marginRight: '0.25rem' }}/> : null}
              {enabled ? filter([value[preferencesKey].mail && getFeatureEnabled(Feature.Mails) && 'Mail', APP_NAME && value[preferencesKey].push && 'Push']).join(', ') : (<>Aus</>)}
            </span>
          </Container>
        </Container>
      ),
      children: (
        <FormLayout embedded>
          <Switch
            id={preferencesKey + '-enabled-switch'}
            label={<Translate message={messages.notifications.enableFor} values={{ title: translate(title) }}/>}
            description={messages.notifications.enableDescription}
            onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], enabled: checked } })}
            value={enabled}
            checkedChildren={<Icon icon={faBellOn}/>}
            unCheckedChildren={<Icon icon={faBellSlash}/>}
            ignoreDefaultValue
          />
          {enabled && (
            <>
              {APP_NAME && (
                <Switch
                  id={preferencesKey + '-push-switch'}
                  label={messages.notifications.enablePush.title}
                  description={messages.notifications.enablePush.description}
                  onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], push: checked } })}
                  value={value[preferencesKey].push}
                  checkedChildren={<Icon icon={faBellOn}/>}
                  unCheckedChildren={<Icon icon={faBellSlash}/>}
                  ignoreDefaultValue
                />
              )}
              {getFeatureEnabled(Feature.Mails) && (
                <Switch
                  id={preferencesKey + '-mail-switch'}
                  label={messages.notifications.enableMail.title}
                  description={messages.notifications.enableMail.description}
                  onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], mail: checked } })}
                  value={value[preferencesKey].mail}
                  checkedChildren={<Icon icon={faBellOn}/>}
                  unCheckedChildren={<Icon icon={faBellSlash}/>}
                  ignoreDefaultValue
                />
              )}
              {getFeatureEnabled(Feature.Sms) && (
                <Switch
                  id={preferencesKey + '-sms-switch'}
                  label={messages.notifications.enableSms.title}
                  description={messages.notifications.enableSms.description}
                  onChange={(checked: boolean) => onChange({ [preferencesKey]: { ...value[preferencesKey], sms: checked } })}
                  value={value[preferencesKey].sms}
                  checkedChildren={<Icon icon={faBellOn}/>}
                  unCheckedChildren={<Icon icon={faBellSlash}/>}
                  ignoreDefaultValue
                />
              )}
              {hasWarning && (
                <Container padding>
                  <Alert type={'warning'} showIcon message={messages.notifications.warning}/>
                </Container>
              )}
              {children}
            </>
          )}
        </FormLayout>
      ),
    };
  };

  const { characteristics, status } = value.newReportNotificationSettings;

  const collapseItems = filter([
    getProductEnabled(Product.OB) && createItem({
      icon: faFileMedicalAlt,
      title: messages.notifications.notificationTypes.NewReport,
      preferencesKey: 'newReportNotification',
      hasWarning: getFeatureEnabled(Feature.ReportNotificationFilters) && (characteristics.length === 0 || status.length === 0),
      children: getFeatureEnabled(Feature.ReportNotificationFilters) && (
        <Guard forbidden={UserType.PAT}>
          <FormLayout label={messages.reports.filters.characteristics} embedded>
            {[
              ReportCharacteristic.NotPathological,
              ReportCharacteristic.Pathological,
              ReportCharacteristic.Extreme,
            ].map(c => (
              <Switch
                key={c}
                id={'characteristic-' + c}
                ignoreDefaultValue
                value={characteristics.includes(c)}
                onChange={v => onChange({
                  newReportNotificationSettings: {
                    ...value.newReportNotificationSettings,
                    characteristics: toggleArrayItem(characteristics, c),
                  },
                })}
                label={messages.reports.characteristics[c]}
              />
            ))}
          </FormLayout>
          <FormLayout label={messages.reports.filters.status} embedded>
            {[
              ReportStatus.Preliminary,
              ReportStatus.Partial,
              ReportStatus.Final,
            ].map(c => (
              <Switch
                key={c}
                id={'status-' + c}
                value={status.includes(c)}
                ignoreDefaultValue
                onChange={v => onChange({
                  newReportNotificationSettings: {
                    ...value.newReportNotificationSettings,
                    status: toggleArrayItem(status, c),
                  },
                })}
                label={get(messages.reports.status, c)}
              />
            ))}
          </FormLayout>
        </Guard>
      ),
    }),

    getProductEnabled(Product.OB) && getFeatureEnabled(Feature.NotificationTrigger) && createItem({
      icon: faBolt,
      title: messages.notifications.notificationTypes.NotificationTrigger,
      preferencesKey: 'customTriggerNotification',
    }),

    getProductEnabled(Product.LE) && createItem({
      icon: faFilePlus,
      title: messages.notifications.notificationTypes.NewExchangeFile,
      preferencesKey: 'newExchangeFileNotification',
    }),

  ]);

  return (
    <FormLayout
      embedded
      className={'notification-settings'}
    >
      <Collapse
        accordion
        expandIconPosition={'end'}
        items={collapseItems}
        defaultActiveKey={collapseItems.length === 1 ? collapseItems[0].key + '' : undefined}
      />
    </FormLayout>
  );
};
