import React from 'react';
import { Control, HelgaContainer, HelgaImageType } from 'components';
import { Translate, useFormatPrice } from 'providers';
import messages from 'messages';
import { AnalysisEbmProperties, AnalysisProperties } from 'interfaces/api';
import { flattenDeep, groupBy, map, sumBy } from 'lodash';

type Props = {
  analyses?: AnalysisProperties[];
  onClose: () => void;
};

type EbmEntry = AnalysisEbmProperties & { shortName: string; name: string };

export const EbmModal: React.FC<Props> = ({ analyses, onClose }) => {

  const labels = messages.orders.ebm;

  const formatPrice = useFormatPrice();

  const allEntries: EbmEntry[] = flattenDeep(analyses.map(({ ebm, shortName, name }) => ebm.map(e => ({ ...e, name, shortName }))));

  return (
    <HelgaContainer
      title={labels.title}
      text={labels.description}
      image={HelgaImageType.Orders}
      className={'price-table'}
      buttons={[(
        <Control
          label={messages.general.close}
          onClick={onClose}
          button={{}}
        />
      )]}
    >
      <ul>
        {map(groupBy(allEntries, a => a.ebm.kapitel + '.' + a.ebm.abschnitt), ((entries, sector) => (
          <React.Fragment key={sector}>
            <li className={'sector-header'}>
              <Translate message={labels.sector} values={{ sector }}/>
            </li>
            {entries.map(e => (
              <li className={'sector-entry'} key={e.shortName}>
                <span className={'label'}>
                  {e.name} (GOP {e.factor}x {e.ebm.gop}):
                </span>
                <span className={'value'}>
                  € {formatPrice(parseFloat(e.ebm.bewertung) * e.factor)}
                </span>
              </li>
            ))}
            <li className={'sector-total'}>
              <span className={'label'}>
                <Translate message={labels.totalSector} values={{ sector }}/>
              </span>
              <span className={'value'}>
                € {formatPrice(sumBy(entries, e => parseFloat(e.ebm.bewertung) * e.factor))}
              </span>
            </li>
          </React.Fragment>
        )))}

        <li className={'total'}>
          <span className={'label'}>
            <Translate message={labels.totalOrder}/>
          </span>
          <span className={'value'}>
            € {formatPrice(sumBy(allEntries, e => parseFloat(e.ebm.bewertung) * e.factor))}
          </span>
        </li>

      </ul>

    </HelgaContainer>
  );

};
